import DemoFormSection from "components/common/DemoFormSection";
import HeroLayout2 from "components/common/hero/HeroLayout2";
import Highlights from "components/common/Highlights";
import InfoSlider from "components/common/InfoSlider";
import TrustedPartnerSection from "components/common/TrustedPartnerSection";
import IndustryFeaturedUpdated from "components/industries/updated/IndustryFeaturedUpdated";
import GlobalLayout from "components/page/GlobalLayout";
import CustomerStoryCarousel from "components/vs/updated/CustomerStoryCarousel";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function AlarmMonitoringLandingPageB() {
  const renderContent = data => {
    const pageData = {
      header: {
        title: "Real-time Alarm Monitoring and Security for Enterprise",
        paragraph:
          "Rhombus' AI-enabled security alerts with real-time agent response will drastically reduce your incidents discovery and resolution time.",
        image: data.headerImage,
        mobileImage: data.headerImageMobile,
        theme: "light",
      },
      industryLogos: [
        {
          img: data.logo1,
          width: "118px",
          alt: "Amazon",
        },
        {
          img: data.logo2,
          width: "130px",
          alt: "Hurley",
        },
        {
          img: data.logo3,
          width: "177px",
          alt: "Aramark",
        },
        {
          img: data.logo4,
          width: "115px",
          alt: "Stryker",
        },
        {
          img: data.logo5,
          width: "149px",
          alt: "Goodwill",
        },
        {
          img: data.logo6,
          width: "137px",
          alt: "Clark Contruction",
        },
      ],
      infoSlides: [
        {
          tabTitle: "Threat Assessment",
          preTitle: "Intelligent, Informed Response",
          title: "Live Threat Assessment",
          description:
            "Ensure accurate and appropriate responses to each unique incident. Agents investigate security alerts in real time using the Rhombus platform and execute emergency protocols based on the situation.",
          image: data.infoSlide1,
        },
        {
          tabTitle: "Notify Contacts",
          preTitle: "Real-Time Communication",
          title: "Notify Your Contacts",
          description:
            "Agents will text or call essential personnel based on the location’s emergency contact list. Contacts are kept in the loop when incidents occur, and false alarms are reduced due to additional verification.",
          image: data.infoSlide2,
        },
        {
          tabTitle: "Audio Deterrents",
          preTitle: "DETER & DE-ESCALATE",
          title: "Issue Audio Deterrents",
          description:
            "Issue an audible deterrent using Rhombus’ Audio Gateway to help neutralize damage or threats before they escalate. Sound a police siren, alarm, custom warning, or use two-way communication to speak directly to interlopers.  ",
          image: data.infoSlide3,
        },
        {
          tabTitle: "Emergency Dispatch",
          preTitle: "ACCELERATE RESPONSE",
          title: "Dispatch Emergency Services",
          description:
            "If an active threat to a person or property is identified, agents will escalate as needed and immediately dispatch local emergency responders to the affected location. ",
          image: data.infoSlide4,
        },
        {
          tabTitle: "Share Data",
          preTitle: "Share Critical Context",
          title: "Share Data with First Responders",
          description:
            "In real time, agents can securely share essential information from the Rhombus Console with emergency services, including footage, livestreams, and location details.",
          image: data.infoSlide5,
        },
      ],
      customerStories: {
        title: "Industry Leaders Trust Rhombus as their Security Solution",
        cards: [
          {
            img: data.story1,
            logo: {
              img: data.cslogo1,
              width: "131px",
              alt: "University Area CDC",
            },
            quote:
              "“I feel like the Rhombus team genuinely cares about our organization and the problems we're trying to solve—they were never just trying to sell us cameras.”",
          },
          {
            img: data.story2,
            logo: {
              img: data.cslogo2,
              width: "143px",
              alt: "Blake's Lota Burger",
            },
            quote:
              "“Similar tech on the surface, but Rhombus had more features, the portal was more user-friendly, and it had everything we needed. That was the start of our wonderful partnership.”",
          },
          {
            img: data.story3,
            logo: {
              img: data.cslogo3,
              width: "217px",
              alt: "Highlands School District",
            },
            quote:
              "“My biggest priority was not the latest discount another company could give me, but that my Superintendent will say that I chose the right partner and security platform in five years. That’s why I chose Rhombus over Verkada.”",
          },
        ],
      },
      highlights: {
        title:
          "Rhombus Uses AI to Identify Security Threats and Agents to Verify Events",
        image: data.highlightImage,
        list: [
          {
            title: "24/7 Automatic Threat Detection",
            description:
              "Rhombus AI Analytics continuously monitor your environment for potential threats, including trespassing and persons-of-interest.",
          },
          {
            title: "Professional Threat Assessment in Real Time",
            description:
              "After-hours security alerts are routed to a TMA Five Diamond Certified dispatcher who investigates via security camera feeds.",
          },
          {
            title: "Rapid Emergency Response",
            description:
              "When a threat is verified, the agent takes immediate action to neutralize the threat and protect your facilities. ",
          },
        ],
      },
      formSection: {
        preTitle: "REQUEST DEMO",
        title: "Interested in Rhombus?",
        description:
          "Get a demo today and see how Rhombus can transform your physical security.",
        learnLines: [
          "Why a cloud-native solution is superior to on-premise video security systems",
          "The benefits of using a unified platform with smart cameras, sensors, and integrations",
          "How to improve safety and productivity with powerful AI analytics",
        ],
        id: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
      },
    };

    return (
      <GlobalLayout color="var(--gradient-light)" landingPage>
        <Helmet>
          <title>Business Alarm Monitoring | Rhombus™ </title>
          <meta
            name="description"
            content="Rhombus’ 24/7 professional real-time threat detection ensures rapid emergency response for companies of all sizes."
          />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <HeroLayout2
          data={pageData.header}
          theme={pageData.header.theme}
          formTitle="Request Demo"
        />
        <IndustryFeaturedUpdated
          title="Trusted by Industry Leaders"
          logos={pageData.industryLogos}
          color="var(--white)"
        />
        <TrustedPartnerSection button={false} />
        <Highlights data={pageData.highlights} />
        <InfoSlider
          data={pageData.infoSlides}
          title="Professional Alarm Monitoring with Rhombus"
          button
        />

        <CustomerStoryCarousel
          data={pageData.customerStories.cards}
          title={pageData.customerStories.title}
        />
        <DemoFormSection data={pageData.formSection} />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/landing-pages/search/img/alarm-monitoring-hero-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      headerImageMobile: file(
        relativePath: {
          eq: "components/landing-pages/search/img/alarm-monitoring-hero-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      infoSlide1: file(
        relativePath: {
          eq: "components/alarms/updated/img/threat-assessment-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: {
          eq: "components/alarms/updated/img/communication-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide3: file(
        relativePath: {
          eq: "components/alarms/updated/img/audio-deterrent-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide4: file(
        relativePath: {
          eq: "components/alarms/updated/img/alert-verification-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide5: file(
        relativePath: { eq: "components/alarms/updated/img/share-data-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      cslogo1: file(
        relativePath: { eq: "components/common/logos/uacdc-white-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      cslogo2: file(
        relativePath: { eq: "components/common/logos/blakes-lotaburger.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      cslogo3: file(
        relativePath: {
          eq: "components/common/logos/highlands-school-district.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      story1: file(
        relativePath: { eq: "components/vs/updated/img/story-1-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      story2: file(
        relativePath: { eq: "components/vs/updated/img/story-2-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      story3: file(
        relativePath: { eq: "components/vs/updated/img/story-3.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      logo1: file(relativePath: { eq: "components/common/logos/amazon.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(relativePath: { eq: "components/common/logos/hurley.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: { eq: "components/common/logos/aramark-black.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(relativePath: { eq: "components/common/logos/stryker.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/common/logos/goodwill-2-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/common/logos/clark-construction-black.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      highlightImage: file(
        relativePath: {
          eq: "components/ai/updated/img/highlight-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
